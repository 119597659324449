import Carousel from '../vendor/carousel/carousel.js';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import download from 'downloadjs';
import 'a11y-dialog';


function generateJPG(node)  {
  htmlToImage.toJpeg(node)
    .then(function (dataUrl) {
      let img = new Image({
        width: 800,
        height: 800,
        pixelRatio: 2,
        quality: .95
      });
      img.src = dataUrl;
      document.body.appendChild(img);
      download(dataUrl, 'image.jpg');
      node.classList.remove('bg-wood');
    })
    .catch(function (error) {
      console.error('oops, something went wrong!', error);
    });
}

function updateFormType(formType) {
  const form = document.getElementById('crime-form');
  const title = document.getElementById('form-dialog-title');
  const shareableType = document.getElementById('shareable-type');

  switch (formType) {
    case 'crime':
      title.innerHTML = 'Report a Crime';
      shareableType.value = 'wanted.html';
      break;
  
    case 'confession':
      title.innerHTML = 'Submit a Confession';
      shareableType.value = 'confession.html';
      break;
  
    case 'conviction':
      title.innerHTML = 'Report an Arrest';
      shareableType.value = 'conviction.html';
      break;
  
    default:
      break;
  }

  form.reset();
}

function updateShareableData() {
  const params = (new URL(document.location)).searchParams;
  const name = params.get("name");
  const image = params.get("image");
  const crimes = params.get("crimes");
  const date = params.get("date");

  document.querySelector('#criminal-name').innerHTML = name;
  if(image != 'default') {
    document.querySelector('#criminal-image').src = image;
  }
  document.querySelector('#crimes').innerHTML = crimes;

  const crimeDate = document.querySelector('#crime-date');
  if(crimeDate) crimeDate.innerHTML = date;
}

window.addEventListener("load", () => {

  // Carousel
  const navigationControlsTemplate = document.querySelector('#carousel-controls');
  const carouselEl = document.querySelector('.carousel');
  if(carouselEl) {
    const carousel = new Carousel({
      root: carouselEl,
      navigationControls: navigationControlsTemplate.content.cloneNode(true),
    });
  }


  // Form buttons
  const formDialog = document.querySelector('#form-dialog');
  if(formDialog) {
    document.getElementById('crime-button').addEventListener('click', (e) => {
      updateFormType('crime');
    });
    document.getElementById('confession-button').addEventListener('click', (e) => {
      updateFormType('confession');
    });
    document.getElementById('conviction-button').addEventListener('click', (e) => {
      updateFormType('conviction');
    }); 
  }


  // Validate Form
  const checkForm = (e) => {
    const form = e.target;
    const crimes = form.querySelectorAll('input[type="checkbox"]:checked');
    const shareableType = form.querySelector('#shareable-type');
    let errorMessage = '';

    if(!crimes.length) {
      errorMessage = 'Please select at least one crime from the list below.';
    } else if(crimes.length > 3) {
      errorMessage = 'Please select a maximum of three crimes.';
    }

    if(errorMessage != '') {
      e.preventDefault();
      const htmlError = document.createElement('div');
      htmlError.classList.add('error')
      htmlError.innerHTML = '<p>' + errorMessage + '</p>';
      form.insertBefore(htmlError, shareableType);
      return false;
    }
  };
  const crimeForm = document.querySelector("#crime-form");
  if(crimeForm) {
    crimeForm.addEventListener("submit", checkForm);
  }


  // Update Shareable
  const shareable = document.querySelector('.shareable');
  if(shareable) {
    updateShareableData();
  }
});